import { russianPaymentCardsNarrowedSet } from "app/constants/CurrencyConstants";

export const reducer: typeof window.kinosite.reducer  = {
	cinema: {
		address: 'ул. Строительная, 2Б',
		place: 'ТЦ «ЯрмаRка»',
		phoneNumbers: ['+7 (8216) 700-950'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/cinemayarmarka',
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: null,
			googleplay: null,
		},
		payment: {
			active: true,
			card: russianPaymentCardsNarrowedSet,
		},
		maps: 'https://yandex.ru/maps/-/CGsRaTOE',
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: false,
	},
	feedback: true,
};
